import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/shared/services/snackBarService.service';

@Component({
  selector: 'app-audit-report-download-popup',
  templateUrl: './audit-report-download-popup.component.html',
  styleUrls: ['./audit-report-download-popup.component.scss'],
})
export class AuditReportDownloadPopupComponent implements OnInit {
  form: FormGroup;
  emailList: string[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  addOnBlur = true;
  maxDate = new Date();
  constructor(
    private snackbarService: SnackbarService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AuditReportDownloadPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { 
    this.form = this.fb.group({
      dateRange: this.fb.group({
        startDate: [null, Validators.required],
        endDate: [null, Validators.required],
      }),
      emailListControl: new FormControl('', [Validators.email])
    });
  }


  onClose(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.emailList = this.data?.emailList || [];
  }
  

  onSave(): void {
    const dateRange = this.form.get('dateRange')?.value;
    const result = {
      emails: this.emailList,
      startDate: dateRange?.startDate ? this.formatDate(new Date(new Date(dateRange.startDate).setDate(new Date(dateRange.startDate).getDate() + 1))) : null,
      endDate: dateRange?.endDate ? this.formatDate(new Date(new Date(dateRange.endDate).setDate(new Date(dateRange.endDate).getDate() + 1))) : null,
    };
    this.dialogRef.close(result);
  }

  private formatDate(date: Date): string {
    return date.toISOString().split('T')[0];
  }

  isFormValid(): boolean {
    const dateRange = this.form.get('dateRange')?.value;
    const hasDates = dateRange?.startDate && dateRange?.endDate;
    const hasEmails = this.emailList.length > 0;
  
    return hasDates && hasEmails;
  }

  openDatepicker(picker: any): void {
    picker.open();
  }

  onReset(): void {
    const result = {
      reset:true
    };
    this.dialogRef.close(result);
  }


  addEmail(event: any): void {
    const input = event.input;
    const value = event.value.trim();

    if (value && this.emailList.length >= 25) {
      this.form.get('emailListControl')?.setErrors({ maxEmails: true });
      this.snackbarService.open('You are not allowed to add more emails. ', 'X', 'warn');
      return;
    }

    if (value && this.isValidEmail(value)) {
      this.emailList.push(value);
      this.form.get('emailListControl')?.setValue('');
    } else if (value) {
      this.form.get('emailListControl')?.setErrors({ invalidEmail: true });
    }

    if (input) {
      input.value = '';
    }
  }


  removeEmail(email: string): void {
    const index = this.emailList.indexOf(email);
    if (index >= 0) {
      this.emailList.splice(index, 1);
    }
  }

  private isValidEmail(email: string): boolean {
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i;
    return emailPattern.test(email);
  }
}

