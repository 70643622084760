import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from './layout/layout.module';
import { HttpClientModule } from '@angular/common/http';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { GlobalService } from './shared/services/global.service';
import { NgChartsModule } from 'ng2-charts';
import { InternationalPhoneNumberModule } from 'ng-phone-number';
import { DateAdapter, MatNativeDateModule, NativeDateAdapter } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoadingIndicatorModule } from './loading-indicator/loading-indicator.module';
import { RouterModule } from '@angular/router';
import { PendingInterceptorModule } from './loading-indicator/pending-interceptor.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';

export function jwtOptionsFactory(globalService: GlobalService) {
  return {
    tokenGetter: () => {
      return globalService.user.accessToken;
    },
  };
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    // SharedModule,
    RouterModule,
    InternationalPhoneNumberModule,
    LoadingIndicatorModule,
    MatSnackBarModule,
    NgChartsModule,
    MatIconModule,
    // Layout Module (Sidenav, Toolbar, Quickpanel, Content)
    LayoutModule,
    // Displays Loading Bar when a Route Request or HTTP Request is pending
    PendingInterceptorModule,
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule, // Ensure this is imported
    NgIdleKeepaliveModule.forRoot(),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [GlobalService],
      },
    })
  ],
  providers: [
    { provide: DateAdapter, useClass: NativeDateAdapter },
    MatNativeDateModule,
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
