<div>
    <div fxLayout="row" fxLayoutAlign="center">
        <h3>Download Audit Report</h3>
    </div>
    <mat-dialog-content>
        <form [formGroup]="form" class="padding" fxLayout="column">
            <mat-form-field appearance="outline">
                <mat-label>Pick a Date Range</mat-label>
                <mat-date-range-input [max]="maxDate" [rangePicker]="picker" formGroupName="dateRange" (click)="openDatepicker(picker)">
                  <input matStartDate placeholder="Start Date" formControlName="startDate">
                  <input matEndDate placeholder="End Date" formControlName="endDate">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>

            <mat-form-field class="example-chip-list" appearance="outline" fxFlex="grow">
                <mat-label>Enter Email ID's (Upto 25)</mat-label>
                <mat-chip-list #chipList formControlName="emailListControl">
                    <mat-chip *ngFor="let email of emailList" (removed)="removeEmail(email)">
                        {{ email }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <input placeholder="Enter Email" [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                        (matChipInputTokenEnd)="addEmail($event)" />
                </mat-chip-list>
                <mat-error *ngIf="form.get('emailListControl').hasError('invalidEmail')">
                    Invalid email format
                </mat-error>
            </mat-form-field>
        </form>
    </mat-dialog-content>

    <div class="dialog-actions" fxLayoutAlign="end">
        <button mat-button class="mr-1" (click)="onClose()">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!isFormValid()" (click)="onSave()">Submit</button>
    </div>
</div>