import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Audit } from "../models/audit/audit.model";
import { RestService } from "./rest.service";
import { ConfigService } from "./utils/config.service";
import { AuditType } from "src/app/audit/enums/audit-types-enum";

@Injectable()
export class AuditService extends RestService<Audit> {
    constructor(http: HttpClient, configService: ConfigService) {
        super('/user-audits', http, configService);
    }

    auditUrl(auditType: AuditType) {
        return auditType == AuditType.App || auditType === AuditType.Group || auditType === AuditType.Event ? '/auto-audits' : '/user-audits';
    }

    updateAudit(data, type){
        return this.makeRequest('patch', `${this.auditUrl(type)}/update-audit`, data);
    }

    getUserAuditDetails(id, data) {
        return this.makeRequest('get', `${this.auditUrl(data.auditType)}/${id}`, data);
    }

    getSelectedUserAudits(data) {
        return this.makeRequest('post', `/selected-user-audits`, data);
    }

    getAuditMetaDetails(id, data) {
        
        return this.makeRequest('get', `${this.auditUrl(data.auditType.toLowerCase())}/audit-userinfo/${id}`, null);
    }

    getTaskMetaDetails(id) {
        return this.makeRequest('get', `/user-audits/audit-taskinfo/${id}`, null);
    }

    getUserAuditTasks(data) {
        return this.makeRequest('get',`${this.auditUrl(data.auditType)}/audit-tasks/${data.id}`, data);
    }

    getAudits(data) {
        return this.makeRequest('get', this.auditUrl(data.auditType), data);
    }

    createAudit(audit) {
        return this.makeRequest('post', this.auditUrl(audit.auditType), audit);
    }
    
    createConnectorAudit(audit) {
        return this.makeRequest('post', '/user-audits', audit)
    }

    addUserComment(data) {
        return this.makeRequest('post', `${this.auditUrl(data.auditType)}/add-comments`, data);
    }

    delegateGroup(data) {
        return this.makeRequest('post', '/audits/delegate-groups', data);
    }

    delegateUser(data) {
        return this.makeRequest('post', '/audits/delegate-users', data);
    }

    rewokeCertiiedUserAudit(data) {
        return this.makeRequest('put',  `${this.auditUrl(data.auditType)}/certify-revoke-audits`, data);
    }

    rewokeCertiedAudittask(data) {
        return this.makeRequest('put',  `${this.auditUrl(data.auditType)}/certify-revoke-audit-tasks`, data);
    }

    reviewCompletedNotify(data) {
        return this.makeRequest('post', `${this.auditUrl(data.auditType)}/review-completed-notify`, data);
    }

    rewokeCertifiedTask(data) {
        return this.makeRequest('put', '/user-audits/certify-revoke-audit-tasks', data);
    }

    enableExtendedView(data) {
        return this.makeRequest('put', '/user-audits/enable-extended-view', data);
    }

    userAutoDelegate(data) {
        return this.makeRequest('post', '/audits/auto-delegate', data);
    }

    // execute user audit
    executeUserAudit(data) {
        return this.makeRequest('post', `${this.auditUrl(data.auditType)}/execute-audit`, data);
    }

    runAdhocScheduler(data) {
        return this.makeRequest('post', '/auto-audits/adhoc-scheduler', data);
    }

    //mark as complete
    markAsComplete(data) {
        return this.makeRequest('patch', `${this.auditUrl(data.auditType)}/mark-as-complete`, data);
    }

    // pull okta manager
    pullOktaManager(data) {
        // api not provided
        return this.makeRequest('post', '/user-audits/sync-user-cloud-data', data);
    }

    updateUserStatus(data) {
        return this.makeRequest('patch', '/user-audits/change-user-status', data);
    }

    assignedAppsGroup(data) {
        return this.makeRequest('get', data.assignedType == 'assigned-app' ? `/api-integrations/assigned-apps/${data.accessId}` : data.assignedType == 'assigned-group' ? `/api-integrations/assigned-groups/${data.accessId}` : `/api-integrations/get-cloud-user-logs/${data.accessId}`, data);
    }

    // create resource audit
    createResourceAudit(data) {
        return this.makeRequest('post', '/user-audits/csv', data);
    }

    generateUserAuditReport(data) {
        return this.makeRequest('post',  `${this.auditUrl(data.auditType)}/generate-audit-report`, data);
    }

    pentestReport(data) {
        return this.makeRequest('post', '/user-audits/pentest-report', data);
    }

    createUpdateSchedule(data, isUpdate) {
        return this.makeRequest(isUpdate ? 'put' : 'post', isUpdate ? '/auto-audits/update-schedule' : '/auto-audits/create-schedule', data);
    }

    getScheduleDetail(data) {
        return this.makeRequest('get', `/auto-audits/get-schedule-detail/${data.id}`, null);
    }

    deleteAudit(data) {
        
        return this.makeRequest('delete', `${this.auditUrl(data.auditType)}/delete-audit/${data.id}`, null);
    }

    removeSchedules(data) {
        return this.makeRequest('delete', `/auto-audits/delete-schedule/${data.id}`, null);
    }

    getResourceTemplates(data) {
        return this.makeRequest('get', '/user-audits/csv-templates', data);
    }

    getResourceTemplateDetail(id) {
        return this.makeRequest('get', `/user-audits/csv-templates/${id}`, null);
    }

    deleteResourceTemplate(data) {
        return this.makeRequest('delete', `/user-audits/csv-templates/${data.id}`, data);
    }

    deleteBulkAudit(data: any) {
        return this.makeRequest('delete', `${this.auditUrl(data.auditType)}/bulk-delete-audits`, data);
    }

    getDelegationWorkflowRules(id, auditType) {
        
        return this.makeRequest('get', `${this.auditUrl(auditType)}/delegation-workflow-rules/${id}`, null)
    }
}