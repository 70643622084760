import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuditReportDownloadPopupComponent } from 'src/app/audit/audit-report-download-popup/audit-report-download-popup.component';
import { User } from 'src/app/shared/models/auth/user.model';
import { Company } from 'src/app/shared/models/company.model';
import { CompanyService } from 'src/app/shared/services';
import { GlobalService } from 'src/app/shared/services/global.service';
import { SnackbarService } from 'src/app/shared/services/snackBarService.service';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'fury-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input()
  @HostBinding('class.no-box-shadow')
  hasNavigation: boolean;
  user: User;
  company: Company;
  isHomeRoute: boolean = false;
  auditReportFilterSetting: boolean = false;

  @Output() openSidenav = new EventEmitter();
  @Output() openQuickPanel = new EventEmitter();

  topNavigation$ = this.themeService.config$.pipe(map(config => config.navigation === 'top'));

  constructor(private themeService: ThemeService, private globalService: GlobalService, 
    private dialog: MatDialog, private router: Router,  private companyService: CompanyService,
    private snackbarService: SnackbarService
  ) {
    this.user = this.globalService.user;
    this.company = this.globalService.company;
  }

  ngOnInit() { 
    this.checkRoute();
    this.router.events.subscribe(() => {
        this.checkRoute();
    });
  }

  checkRoute(): void{
    this.isHomeRoute = this.router.url === '/';
    this.auditReportFilterSetting = this.globalService.company.auditReportFilter;
  }

  openReportDownloadDialog(): void {
    this.dialog.open(AuditReportDownloadPopupComponent, {
      width: '50vw',  
      maxHeight: '100vh',
    }).afterClosed().subscribe(result => {
      if (result) {
        let req = {
          companyId: this.company.id,
          startDate: result.startDate,
          endDate: result.endDate,
          emailList: result.emails,
        }
        this.companyService.generateCompanyAuditReport(req).subscribe((res) => {
          this.snackbarService.open(res.message, 'Close', 'success');
        });
      }
    });
  }

}
